import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Hero from "../../components/hero/HeroLight";
import Index from "../../components/about/index";
import Address from "../../components/Address";
import Portfolio from "../../components/portfolio/PortfolioLight";
import Contact from "../../components/Contact";
import Social from "../../components/Social";

const menuItem = [
  { icon: "fa-home", menuName: "Главная" },
  { icon: "fa-user", menuName: "Инфо" },
  { icon: "fa-briefcase", menuName: "Портфолио" },
  { icon: "fa-envelope-open", menuName: "Контакты" },
  { icon: "fa-shopping-cart", menuName: "Магазин", href: "https://shop.dev-spb.ru" },
];

const HomeLight = () => {
  document.body.classList.add("light");

  const handleTabClick = (href) => {
    if (href) {
      window.open(href, "_blank"); // Открываем ссылку в новой вкладке
      window.location.href = "/light"; // Переходим на главную страницу
    }
  };

  return (
      <div className="green">
        <div className="demo-sticker">
          <a href="/dark">
            <i className="fa fa-moon-o" aria-hidden="true"></i>
          </a>
        </div>
        <Tabs>
          <div className="header">
            <TabList className="icon-menu revealator-slideup revealator-once revealator-delay1">
              {menuItem.map((item, i) => (
                  <Tab
                      className="icon-box"
                      key={i}
                      onClick={() => handleTabClick(item.href)}
                  >
                    <i className={`fa ${item.icon}`}></i>
                    <h2>{item.menuName}</h2>
                  </Tab>
              ))}
            </TabList>
          </div>
          {/* End Menu Content */}

          <div className="tab-panel_list">
            {/* Hero Content Starts */}
            <TabPanel className="home">
              <div
                  className="container-fluid main-container container-home p-0"
                  data-aos="fade-up"
                  data-aos-duration="1200"
              >
                <div className="color-block d-none d-lg-block"></div>
                <Hero />
              </div>
            </TabPanel>
            {/* Hero Content Ends */}

            {/* About Content Starts */}
            <TabPanel className="about">
              <div data-aos="fade-up" data-aos-duration="1200">
                <div className="title-section text-left text-sm-center">
                  <h1>
                    О <span>НАС</span>
                  </h1>
                  <span className="title-bg">Информация</span>
                </div>
                {/* End title */}
                <Index />
              </div>
            </TabPanel>
            {/* About Content Ends */}

            {/* Portfolio Content Starts */}
            <TabPanel className="portfolio">
              <div
                  className="title-section text-left text-sm-center"
                  data-aos="fade-up"
                  data-aos-duration="1200"
              >
                <h1>
                  наши <span>работы</span>
                </h1>
                <span className="title-bg">портфолио</span>
              </div>
              {/* End title */}
              <div
                  className="container grid-gallery main-content"
                  data-aos="fade-up"
                  data-aos-duration="1200"
                  data-aos-delay="100"
              >
                <Portfolio />
              </div>
              {/* End grid gallery */}
            </TabPanel>
            {/* Portfolio Content Ends */}

            {/* Contact Content Starts */}
            <TabPanel className="contact">
              <div
                  className="title-section text-left text-sm-center"
                  data-aos="fade-up"
                  data-aos-duration="1200"
              >
                <h1>
                  наши <span>контакты</span>
                </h1>
                <span className="title-bg">обратная связь</span>
              </div>
              <div
                  className="container"
                  data-aos="fade-up"
                  data-aos-duration="1200"
              >
                <div className="row">
                  {/*  Left Side Starts */}
                  <div className="col-12 col-lg-4">
                    <h3 className="text-uppercase custom-title mb-0 ft-wt-600 pb-3">
                      Мы всегда на связи!
                    </h3>
                    <p className="open-sans-font mb-4">
                      Не стесняйтесь обращаться к нам. Мы всегда открыты для
                      обсуждения новых проектов, творческих идей или возможностей.
                    </p>
                    <Address />
                    {/* End Address */}

                    <Social />
                    {/* End Social */}
                  </div>
                  {/* Left Side Ends */}

                  {/*  Contact Form Starts  */}
                  <div className="col-12 col-lg-8">
                    <Contact />
                  </div>
                  {/*  Contact Form Ends */}
                </div>
              </div>
              {/* End .container */}
            </TabPanel>
            {/* Contact Content Ends */}
          </div>
        </Tabs>
      </div>
  );
};

export default HomeLight;
