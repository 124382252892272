import React from "react";

const Address = () => {
  return (
    <>
      <p className="open-sans-font custom-span-contact position-relative">
        <i className="fa fa-envelope-open position-absolute"></i>
        <span className="d-block">email</span>{" "}
        <a href="mailto:support@dev-spb.ru">support@dev-spb.ru</a>
      </p>
      {/* End .custom-span-contact */}

      <p className="open-sans-font custom-span-contact position-relative">
        <i className="fa fa-telegram position-absolute"></i>
        <span className="d-block">telegram</span>{" "}
        <a href="https://t.me/devspb_studio">@devspb_studio</a>
      </p>
      {/* End .custom-span-contact */}
    </>
  );
};

export default Address;
