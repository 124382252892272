import React from "react";

const ModalOneContent = ({ projectName, clientName, languages, previewLink, imageUrl }) => {
  return (
    <div className="slideshow">
      <figure>
        {/* Project Details Starts */}
        <figcaption>
          <h3>{projectName}</h3>
          <div className="row open-sans-font">
            <div className="col-12 col-sm-6 mb-2">
              <i className="fa fa-file-text-o pr-2"></i>
              <span className="project-label">Проект</span>:{" "}
              <span className="ft-wt-600 uppercase">{projectName}</span>
            </div>
            {/* End .col */}

            <div className="col-12 col-sm-6 mb-2">
              <i className="fa fa-user-o pr-2"></i>
              <span className="project-label">Клиент</span>:{" "}
              <span className="ft-wt-600 uppercase">{clientName}</span>
            </div>
            {/* End .col */}

            <div className="col-12 col-sm-6 mb-2">
              <i className="fa fa-code pr-2"></i>
              <span className="project-label">Стек</span>:{" "}
              <span className="ft-wt-600 uppercase">{languages.join(", ")}</span>
            </div>
            {/* End .col */}

            <div className="col-12 col-sm-6 mb-2">
              <i className="fa fa-external-link pr-2"></i>
              <span className="project-label">Ссылка</span>:{" "}
              <span className="ft-wt-600 uppercase">
                <a href={previewLink} target="_blank" rel="noreferrer">
                  {previewLink}
                </a>
              </span>
            </div>
            {/* End .col */}
          </div>
          {/* End .row */}
        </figcaption>
        {/* Project Details Ends */}

        {/* Main Project Content Starts */}
        <img src={imageUrl} alt="Portfolio" />
        {/* Main Project Content Ends */}
      </figure>
    </div>
    // End .slideshow
  );
};

export default ModalOneContent;
