import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import ModalOneContent from "./modal/ModalOneContent";
import axios from 'axios';

const Portfolio = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [portfolioList, setPortfolioList] = useState([]);
  const [modalData, setModalData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://backend.dev-spb.ru/api/portfolio/all');
        setPortfolioList(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const toggleModalOne = () => {
    setIsOpen(!isOpen);
  };

  const handlePortfolioItemClick = (portfolioItem) => {
    setModalData(portfolioItem); // Set clicked portfolio item data to modalData
    toggleModalOne();
  };

  return (
    <div className="portfolio-tab-content">
      <ul className="row grid justify-content-center">
        {portfolioList.map((portfolioItem) => (
          <li
            key={portfolioItem.pk}
            data-aos="fade-right"
            data-aos-duration="1200"
            data-aos-delay="0"
            onClick={() => handlePortfolioItemClick(portfolioItem)}
          >
            <figure>
              <img src={portfolioItem.avatar} alt="Portfolio" />
              <div className="hover-content-wrapper">
                <span className="content-title">{portfolioItem.name}</span>
              </div>
            </figure>
          </li>
        ))}
      </ul>
      
      {/* Modal */}
      <Modal
        isOpen={isOpen}
        onRequestClose={toggleModalOne}
        contentLabel="Portfolio Modal"
        className="custom-modal dark"
        overlayClassName="custom-overlay dark"
        closeTimeoutMS={500}
      >
        {modalData && (
          <div>
            <button className="close-modal" onClick={toggleModalOne}>
              <img src="/img/cancel.svg" alt="close icon" />
            </button>
            <div className="box_inner portfolio">
              <ModalOneContent
                projectName={modalData.name}
                clientName={modalData.client.name}
                languages={modalData.portfolio_languages.map(item => item.language.name)}
                previewLink={modalData.preview != null ? modalData.preview : 'website-preview-link'} // Replace with actual logic for preview link
                imageUrl={modalData.avatar}
              />
            </div>
          </div>
        )}
      </Modal>
      {/* End Modal */}
    </div>
  );
};

export default Portfolio;
