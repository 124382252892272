import React from "react";
import { useForm } from "react-hook-form";
import emailjs from "emailjs-com";

const Contact = () => {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const onSubmit = (data, e) => {
        e.preventDefault();

        emailjs
            .send(
                "service_devspb", // Замените на ваш Service ID из EmailJS
                "template_devspb", // Замените на ваш Template ID из EmailJS
                {
                    name: data.name,
                    email: data.email,
                    subject: data.subject,
                    message: data.message,
                },
                "Qbd22fxdYvjv2IJez" // Замените на ваш User ID из EmailJS
            )
            .then(
                (result) => {
                    console.log(result.text);
                    alert("Сообщение успешно отправлено!");
                },
                (error) => {
                    console.log(error.text);
                    alert("Произошла ошибка при отправке сообщения.");
                }
            );

        e.target.reset();
    };

    return (
        <>
            <form className="contactform" onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                    <div className="col-12 col-md-6">
                        <div className="form-group">
                            <input
                                {...register("name", { required: true })}
                                type="text"
                                name="name"
                                placeholder="ВАШЕ ИМЯ"
                            />
                            {errors.name && errors.name.type === "required" && (
                                <span className="invalid-feedback">Требуется указать имя</span>
                            )}
                        </div>
                    </div>
                    {/* End .col */}

                    <div className="col-12 col-md-6">
                        <div className="form-group">
                            <input
                                {...register(
                                    "email",
                                    {
                                        required: "Требуется Email",
                                        pattern: {
                                            value: /\S+@\S+\.\S+/,
                                            message: "Введенное значение не соответствует формату электронной почты",
                                        },
                                    },
                                    { required: true }
                                )}
                                type="email"
                                name="email"
                                placeholder="ВАШ EMAIL"
                            />
                            {errors.email && (
                                <span className="invalid-feedback">{errors.email.message}</span>
                            )}
                        </div>
                    </div>
                    {/* End .col */}

                    <div className="col-12 col-md-12">
                        <div className="form-group">
                            <input
                                {...register("subject", { required: true })}
                                type="text"
                                name="subject"
                                placeholder="ТЕМА"
                            />
                            {errors.subject && (
                                <span className="invalid-feedback">Требуется тема</span>
                            )}
                        </div>
                    </div>
                    {/* End .col */}

                    <div className="col-12">
                        <div className="form-group">
              <textarea
                  {...register("message", { required: true })}
                  name="message"
                  placeholder="ВАШЕ СООБЩЕНИЕ"
              ></textarea>
                            {errors.message && (
                                <span className="invalid-feedback">Требуется сообщение</span>
                            )}
                        </div>
                    </div>
                    {/* End .col */}

                    <div className="col-12">
                        <button type="submit" className="button">
                            <span className="button-text">Отправить</span>
                            <span className="button-icon fa fa-send"></span>
                        </button>
                    </div>
                    {/* End .col */}
                </div>
            </form>

            {/* End contact */}
        </>
    );
};

export default Contact;
